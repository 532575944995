import Phaser from "phaser"

export class Bootstrap extends Phaser.Scene {
  constructor() {
    super("bootstrap")
  }

  init() {}

  preload() {
    this.load.image("redball", "/2d/5.webp")
    this.load.image("goldball", "/2d/3.webp")
    this.load.image("greenball", "/2d/4.webp")
    this.load.image("blueball", "/2d/2.webp")

    // snow
    this.load.image("snow1", "/snow/1.png")
    this.load.image("snow2", "/snow/2.png")
    this.load.image("snow3", "/snow/3.png")
    this.load.image("snow4", "/snow/4.png")

    this.load.image("Pixel_E", "/snow/Pixel_E.png")
    this.load.image("Pixel_G", "/snow/Pixel_G.png")
    this.load.image("Pixel_R", "/snow/Pixel_R.png")
    this.load.image("Pixel_W", "/snow/Pixel_W.png")

    // snow effect
    this.load.plugin("rexcircularprogressplugin", "/plugin/rexcircularprogressplugin.min.js", true)

    // landing page assets
    this.load.image("background", "/2d/BG.webp")

    // grow light
    this.load.image("greenlight", "/2d/G_glow.webp")
    this.load.image("redlight", "/2d/R_glow.webp")
    this.load.image("yellowlight", "/2d/Y_glow.webp")
    this.load.image("bluelight", "/2d/B_glow.webp")
    // load logo
    this.load.image("logo", "/2d/logo.webp")

    // logo landing
    this.load.image("logolanding", "/2d/logolanding.webp")
    // load light
    this.load.image("light", "/2d/light.webp")

    // load sprite sheet , jsonfile name closeEye.json png name closeEye.png
    // this.load.spritesheet("eye", "/asset/closeEye.png", {
    //   frameWidth: 328,
    //   frameHeight: 155,
    // })

    // gradient effrect
    this.load.image("gradient_red", "/2d/red_filter.webp")
    this.load.image("gradient_green", "/2d/green_filter.webp")
    this.load.image("gradient_yellow", "/2d/yellow_filter.webp")
    this.load.image("gradient_blue", "/2d/blue_gradient.webp")

    this.load.image("redbg", "/2d/redbg.webp")

    let path = "/webp/"
    let assetname = "Front_Anim_"
    for (let i = 0; i <= 96; i++) {
      let current = i.toString().padStart(5, "0")
      this.load.image(assetname + current, path + assetname + current + ".webp")
    }

    // load cup
    this.load.image("cup", "/2d/ornament.webp")

    // load ball
    this.load.image("bellball", "/2d/score.webp")

    // yellow ball
    this.load.image("yellowball", "/2d/yellow_updated_ball.webp")

    // load count down
    this.load.spritesheet("countdown", "/2d/countdown.webp", {
      frameWidth: 300,
      frameHeight: 300,
    })

    // superpower
    this.load.image("superpower", "/2d/superpower.webp")

    // load time up
    this.load.image("timeup", "/2d/Timeup.webp")

    // load sound
    this.load.audio("snowpuff", "/mp3/snowpuff9.mp3")
    this.load.audio("PowerMode1", "/mp3/PowerMode1.mp3")
    this.load.audio("ChangePattern1", "/mp3/ChangePattern1.mp3")
    this.load.audio("Ending", "/mp3/Ending5.mp3")
    this.load.audio("bgmusic", "/mp3/GAME_BGM.mp3")

    // loading progree
    this.load.on("progress", function (value) {
      try {
        window?.setCurrentPage(value * 100)
      } catch (err) {
        console.log(err)
      }
    })
  }

  create() {
    // check already loaded
    this.anims.create({
      key: "redball",
      frames: [{ key: "redball" }],
    })

    this.anims.create({
      key: "goldball",
      frames: [{ key: "goldball" }],
    })

    this.anims.create({
      key: "greenball",
      frames: [{ key: "greenball" }],
    })

    this.anims.create({
      key: "blueball",
      frames: [{ key: "blueball" }],
    })

    this.anims.create({
      key: "redlight",
      frames: [{ key: "redlight" }],
    })

    this.anims.create({
      key: "bluelight",
      frames: [{ key: "bluelight" }],
    })

    this.anims.create({
      key: "greenlight",
      frames: [{ key: "greenlight" }],
    })

    this.anims.create({
      key: "yellowlight",
      frames: [{ key: "yellowlight" }],
    })

    this.anims.create({
      key: "gradient_red",
      frames: [{ key: "gradient_red" }],
    })

    this.anims.create({
      key: "gradient_green",
      frames: [{ key: "gradient_green" }],
    })

    this.anims.create({
      key: "gradient_yellow",
      frames: [{ key: "gradient_yellow" }],
    })

    this.anims.create({
      key: "gradient_blue",
      frames: [{ key: "gradient_blue" }],
    })

    this.anims.create({
      key: "landingvideo",
      frames: ArrayFrame(0, 96, false),
      frameRate: 22,
    })

    this.anims.create({
      key: "closeEye",
      frames: ArrayFrame(55, 96, false),
      repeat: -1,
      frameRate: 22,
    })

    this.anims.create({
      key: "countdown",
      frames: this.anims.generateFrameNumbers("countdown", { start: 0, end: 3 }),
      frameRate: 1,
    })

    let preloaddd = document.querySelector(".preloaddd")
    preloaddd.classList.add("preloaddd--hide")

    this.scene.launch("landing")

    // this.scene.launch("landing")

    // if (this.scene.get("game")) {
    //   this.scene.launch("game")
    // }
    // if (this.scene.get("LastPage")) {
    //   this.scene.launch("LastPage")
    // }
  }

  startScene(e) {
    this.scene.launch(e)
  }

  update() {}
}

function ArrayFrame(start, end, reverse) {
  if (reverse) {
    let arr = []
    for (let i = start; i >= end; i--) {
      arr.push({ key: "Front_Anim_" + i.toString().padStart(5, "0") })
    }
    return arr
  } else {
    let arr = []
    for (let i = start; i <= end; i++) {
      arr.push({ key: "Front_Anim_" + i.toString().padStart(5, "0") })
    }
    return arr
  }
}
