import React, { useEffect, useMemo } from "react"
import { LandingPage, FormPage, Instuction, Lower2000, Bigger2000, LastPage, LeaderBroad } from "./pages"
import { useStore } from "../store/index"
import SVGComponent from "./components/svg"
import { useSuperFan } from "../context"
import { LegerLine } from "./components"
import axios from "axios"
const Overlay = ({ phaserRef }) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const { endGame } = useStore((state) => state)
  const { customType, preScore, info, submit } = useSuperFan()
  const { setShareImgUrl } = useStore((state) => ({
    setShareImgUrl: state.setShareImgUrl,
  }))

  useMemo(() => {
    if (endGame) {
      let phaserContainer = document.getElementById("phaser-container")
      phaserContainer.style.pointerEvents = "all"

      phaserRef.current.scene.scenes[1].endGamehander()

      if (preScore) {
        if (preScore < parseInt(phaserRef.current.scene.scenes[1].pointText.text)) {
          customType("score", parseInt(phaserRef.current.scene.scenes[1].pointText.text))
          customType("playHistory", phaserRef.current.scene.scenes[1].playHistory.toString())

          submit({
            score: parseInt(phaserRef.current.scene.scenes[1].pointText.text),
          })
        }
      } else {
        customType("score", parseInt(phaserRef.current.scene.scenes[1].pointText.text))
        customType("playHistory", phaserRef.current.scene.scenes[1].playHistory.toString())

        submit({
          score: parseInt(phaserRef.current.scene.scenes[1].pointText.text),
        })
      }

      if (info._name !== null) {
        setTimeout(() => {
          phaserRef.current.scene.scenes[3]._name = info._name
          phaserRef.current.scene.scenes[3]._score = phaserRef.current.scene.scenes[1].pointText.text
          phaserRef.current.scene.scenes[0].startScene("LastPage")

          axios
            .get("https://coach-holiday-leaderbroad-aqnp.onrender.com/getimg", {
              params: {
                name: info._name,
                score: phaserRef.current.scene.scenes[1].pointText.text,
                date: Date().split("(")[0],
                contry: Date().split("(")[1].split(")")[0],
              },
            })
            .then((res) => {
              setShareImgUrl(res.data)
            })
          setCurrentPage(7)
        }, 1500)
      } else {
        if (parseInt(phaserRef.current.scene.scenes[1].pointText.text) < 2000) {
          setTimeout(() => {
            setCurrentPage(5)
          }, 1500)
        } else {
          setTimeout(() => {
            setCurrentPage(6)
          }, 1500)
        }
      }
    }
  }, [endGame])

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        width: "100vw",
        height: "100svh",
        zIndex: 2,
        pointerEvents: currentPage === 4 ? "none" : "auto",
        maxWidth: "600px",
      }}>
      <Instuction phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <LandingPage phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <FormPage phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <Lower2000 phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <Bigger2000 phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <LastPage phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <LeaderBroad phaserRef={phaserRef} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <LegerLine currentPage={currentPage} />
    </div>
  )
}

export const Preload = () => {
  const [currentPage, setCurrentPage] = React.useState(1)
  useEffect(() => {
    window.setCurrentPage = (e) => {
      setCurrentPage(e)
    }
  }, [])
  return (
    <div
      className="preloaddd"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
        width: "100vw",
        height: "100svh",
        backgroundColor: "#c41f32",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div>
        <div
          className="imgContainer"
          style={{
            height: "20svh",
          }}>
          <img src="/2d/loading.gif" alt="loadingg" />
        </div>
        {/* <SVGComponent start={currentPage} /> */}
      </div>
    </div>
  )
}

export default Overlay
